







































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetKeshiOptions, GetDoctorOptions } from "@/request/datacenter";
@Component({})
export default class Name extends Vue {
  @Prop()
  private type: any;
  @Prop()
  private title: any;
  @Prop()
  private ifShowKeshi: any; // 是否显示科室筛选框
  @Prop()
  private ifShowDoctor: any; // 是否显示医生筛选框
  @Prop()
  private ifShowChufang: any; // 是否显示处方类型筛选框
  @Prop()
  private ifShowSearch: any; // 是否显示搜索框
  @Prop()
  private searchW: any; // 搜索框宽度
  @Prop()
  private placeholderText: any; // 搜索框的提示词
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.filters = JSON.parse(JSON.stringify(this.data));
  }
  private filters: any;
  private keshis: any = [];
  private doctors: any = [];
  private chufangs: any = [];
  private disabledStartDate: any = this.beginDate();
  private disabledEndDate: any = this.endDate();
  private beginDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filters.endTime) {
          return (
            time.getTime() > new Date(self.filters.endTime).getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filters.startTime) {
          return (
            new Date(self.filters.startTime).getTime() > time.getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private getChufang(e: any) {}
  private search() {
    this.$emit("search");
  }
  private change() {
    this.$emit("change", this.filters);
    this.$forceUpdate();
    console.log(this.filters);
  }
  private getKeshi() {
    const params: any = {
      params: {
        type: this.type,
      },
    };
    GetKeshiOptions(this, params).then((res: any) => {
      this.keshis = res;
    });
  }
  private getDoctor() {
    const params: any = {
      params: {
        type: this.type,
      },
    };
    GetDoctorOptions(this, params).then((res: any) => {
      this.doctors = res;
    });
  }
  private mounted() {
    this.getKeshi();
    this.getDoctor();
  }
}
