












import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Class extends Vue {
  private get patient() {
    return this.$store.state.patient;
  }
}
